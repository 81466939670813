import { HTMLRichTextMapSerializer, RTLinkNode } from '@prismicio/client';

export const componentHeadline: HTMLRichTextMapSerializer = {
    heading2: ({ children }) => `<h2 class="heading">${children}</h2>`,
    heading3: ({ children }) => `<h3 class="heading">${children}</h3>`,
    paragraph: ({ children }) => `<p class="heading">${children}</p>`
};

export const componentHeadlineBlack: HTMLRichTextMapSerializer = {
    heading2: ({ children }) => `<h2 class="heading-black">${children}</h2>`,
    heading3: ({ children }) => `<h3 class="heading-black">${children}</h3>`,
    paragraph: ({ children }) => `<p class="heading-black">${children}</p>`
};

export const headingSerializer: HTMLRichTextMapSerializer = {
    heading1: ({ children }) => `<h1 class="heading">${children}</h1>`,
    heading2: ({ children }) => `<h2 class="heading">${children}</h2>`,
    heading3: ({ children }) => `<h3 class="heading">${children}</h3>`,
    paragraph: ({ children }) => `<p class="heading">${children}</p>`
};

export const titleSerializer: HTMLRichTextMapSerializer = {
    heading1: ({ children }) => `<h1 class="text-size-l">${children}</h1>`,
    heading2: ({ children }) => `<h2 class="text-size-l">${children}</h2>`,
    heading3: ({ children }) => `<h3 class="text-size-l">${children}</h3>`,
    paragraph: ({ children }) => `<p class="text-size-l">${children}</p>`
};

export const textSizeMSerializer: HTMLRichTextMapSerializer = {
    heading2: ({ children }) => `<h2 class="text-size-m">${children}</h2>`,
    heading3: ({ children }) => `<h3 class="text-size-m">${children}</h3>`,
    paragraph: ({ children }) => `<p class="text-size-m">${children}</p>`
};

export const descriptionSmallSerializer: HTMLRichTextMapSerializer = {
    list: ({ children }) => '<ul class="list-unstyled mt-5 text-size-xxs">' + children + '</ul>',
    paragraph: ({ children }) => '<p class="text-size-xxs">' + children + '</p>',
    listItem: ({ children }) => '<li class="mt-2 prismic-list-item-check text-size-xxs">' + children + '</li>',
    hyperlink: ({ children, node }) => serializeHyperlink(children, node)
};

export const descriptionSerializer: HTMLRichTextMapSerializer = {
    list: ({ children }) => '<ul class="list-unstyled mt-5">' + children + '</ul>',
    listItem: ({ children }) => '<li class="mt-2 prismic-list-item-check">' + children + '</li>',
    hyperlink: ({ children, node }) => serializeHyperlink(children, node)
};

export const linkSerializer: HTMLRichTextMapSerializer = {
    hyperlink: ({ children, node }) => serializeHyperlink(children, node)
};

export function serializeHyperlink(children: string, node: RTLinkNode, linkStyle: 'white' | 'red' = 'red') {
    let link;
    const { link_type, url } = node.data;
    switch (link_type) {
        case 'Document': {
            const { type, uid } = node.data;
            switch (type) {
                case 'standard-page':
                    link = `/${uid!.replace('startseite', '')}`;
                    break;
            }
            break;
        }
        default:
            link = url;
    }

    if (linkStyle === 'red') {
        return `<a href="${link}" class="text-decoration-underline color-red underline-offset">${children}</a>`;
    } else {
        return `<a href="${link}" class="text-decoration-underline color-white">${children}</a>`;
    }
}
