import { Pipe, PipeTransform } from '@angular/core';
import { asText, KeyTextField, RichTextField } from '@prismicio/client';

@Pipe({
  name: 'renderText',
  standalone: true
})
export class RenderTextPipe implements PipeTransform {

    transform(value: RichTextField | KeyTextField): string | null {
        if (!value) {
            return '';
        }

        if (typeof value === 'string') {
            return value;
        }

        return asText(value);
    }

}
