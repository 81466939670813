@if (link) {
    <ng-container [ngSwitch]="link?.link_type">
        <!-- Document -->
        <ng-container *ngSwitchCase="'Document'">
            <ng-container [ngSwitch]="link.type">
                <!-- Standard-Page -->
                <a *ngSwitchCase="'standard-page'" [routerLink]="link.uid === 'startseite' ? '' : '/' + link.uid" [fragment]="anker" [routerLinkActive]="routerLinkActive" [class]="cssClass">
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                </a>

                <button *ngSwitchCase="'modal'"  [class]="cssClass" (click)="openModal(link)">
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                </button>
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </ng-container>
        </ng-container>

        <!-- Web -->
        <a *ngSwitchCase="'Web'" [hidden]="!link.url" [href]="link.url" [target]="link.target" [class]="cssClass" rel="noopener nofollow">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>

        <!-- Media -->
        <a *ngSwitchCase="'Media'" [hidden]="!link.url" [href]="link.url" [class]="cssClass" rel="noopener nofollow" target="_blank">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>

        <!-- Any -->
        <ng-container *ngSwitchCase="'Any'">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </ng-container>

        <!-- Arrow -->
        <div class="arrow" *ngIf="(link?.['id'] || link.url) && showArrow">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="30px"
                height="18px"
                viewBox="0 0 30 18"
                enable-background="new 0 0 30 18"
                xml:space="preserve"
            >
                <path
                    class="handle"
                    d="M20.305,16.212c-0.407,0.409-0.407,1.071,0,1.479s1.068,0.408,1.476,0l7.914-7.952c0.408-0.409,0.408-1.071,0-1.481
	l-7.914-7.952c-0.407-0.409-1.068-0.409-1.476,0s-0.407,1.071,0,1.48l7.185,7.221L20.305,16.212z"
                ></path>
                <path
                    class="bar"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1,8h28.001c0.551,0,1,0.448,1,1c0,0.553-0.449,1-1,1H1c-0.553,0-1-0.447-1-1
	C0,8.448,0.447,8,1,8z"
                ></path>
            </svg>
        </div>
    </ng-container>
} @else {
    <ng-container *ngTemplateOutlet="content"></ng-container>
}
<ng-template #content>
    <ng-content></ng-content>
</ng-template>
