<div class="container px-3 py-4 p-lg-5">
    <div class="row mb-2">
        <div class="col-12 d-flex justify-content-end">
            <button class="btn-unstyle" (click)="bsModalRef?.hide()">
                <img class="icon-small" src="assets/icons/close.svg" />
            </button>
        </div>
    <div class="row align-items-center mb-2">
        <div class="col-12">
            <p class="text-size-l mb-0">{{ data?.data?.title | renderText }}</p>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <div [innerHtml]="data?.data?.description | renderHtml"></div>
        </div>
    </div>
    <div class="row mt-4">
        @for (item of data?.data?.activities; track $index) {
            <div class="col-lg-4 color-black fw-bold">{{item?.date}}</div>
            <div class="col-lg-8" [innerHtml]="item?.text | renderHtml"></div>
        }
    </div>
</div>
