import { SelectField } from '@prismicio/client';

export function getMargin(body: any) {
    return {
        'mt-5 mt-lg-6': body?.primary.margin_top,
        'mb-5 mb-lg-6': body?.primary.margin_bottom
    };
}

export function getButtonClass(style: string): string {
    switch (style) {
        case 'Link':
            return 'btn';
        case 'Button (Primär)':
            return 'btn btn-green-filled';
        case 'Button (Sekundär)':
            return 'btn btn-white';
        default:
            return 'btn';
    }
}

export function getStyle(pin: SelectField): {[key: string]: string} {
    switch(pin) {
        case 'Koffer':
            return {
                'top': '-50px',
                'right': '-50px'
            };
        case 'Pflaster':
            return {
                'bottom': '50px',
                'right': '-50px'
            };
        case 'Drache':
            return {
                'bottom': '50px',
                'left': '-60px'
            };
        default:
            return {};
    }
}

export function getPinClass(pin: SelectField): string {
    return 'pin-' + pin;
}
