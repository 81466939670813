import { asText as asText$1 } from "../richtext/asText.js";
const asText = (richTextField, ...configObjectOrTuple) => {
  if (richTextField) {
    const [configObjectOrSeparator] = configObjectOrTuple;
    let config;
    if (typeof configObjectOrSeparator === "string") {
      config = {
        separator: configObjectOrSeparator
      };
    } else {
      config = { ...configObjectOrSeparator };
    }
    return asText$1(richTextField, config.separator);
  } else {
    return null;
  }
};
export {
  asText
};

