import { Component, inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { JsonPipe } from '@angular/common';
import { RenderTextPipe } from '../shared/prismic/render-text.pipe';
import { RenderHtmlPipe } from '../shared/prismic/render-html/render-html.pipe';

@Component({
  selector: 'app-modal',
  standalone: true,
    imports: [
        JsonPipe,
        RenderTextPipe,
        RenderHtmlPipe
    ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent {
    data?: any;
    public bsModalRef = inject(BsModalRef);
}
